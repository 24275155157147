export default {
	data: () => ({
		langEs: {
			links: {
				home: 'Inicio',
				shop: 'Tienda',
				specialist: "Especialistas"
			},
			title: 'Tienda',
			filtreTitle: 'Filtrar productos',
			brand: 'Marca',
			price: 'Precio',
			categorie: 'Categoría',
			sorted: 'Rango',
			result: 'Resultados',
			btnAdd: 'Añadir al carrito',
			loadPage: 'Cargar más'
		}
	})
}



