import FreeIpApiService from '@/services/ip'
export default {
    methods: {
        async getVisitorCountryCode () {
            if ( !localStorage.getItem('userCountryCode') ) {
                await this.setVisitorCountryCode()
            }
            return localStorage.getItem('userCountryCode')
        },
      
        async setVisitorCountryCode () {
            const apiService = new FreeIpApiService();
            const visitorCountryCode = await apiService.getCurrentVisitorCountryCode()
        
            if ( !visitorCountryCode ) {
                throw new Error('Failed to get country')
            }
            localStorage.setItem('userCountryCode', visitorCountryCode)
        }
    }
}