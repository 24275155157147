<template>
<div v-if="user && loaded">
  <SpecialistShopHeader/>
  <div class="my_container">
    <Breadcrumbs>
      <!-- <div class="links">
        <router-link to="/">{{$t('shop.links.home')}}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
          <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <router-link to="/specialists">{{$t('shop.specialist')}}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <router-link :to="`/specialist/${user.id}`">{{user.name}}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <router-link class="curr" :to="{name: 'shop', params: {id: user.id}}" >{{$t('shop.links.shop')}}</router-link>
      </div> -->
      <template v-slot:title>
        <h1>{{user.name}}</h1>
      </template>
    </Breadcrumbs>
  </div>
  <div class="shop__filters__mobile" :class="{ 'active': productFilter.mobileVersion }">
    <div class="top">
      <span>{{$t('shop.filtreTitle')}}</span>
      <div class="close" @click="productFilter.mobileVersion = !productFilter.mobileVersion"></div>
    </div>
    <div class="dd__card">
      <div class="title">
        <span>{{$t('shop.brand')}}</span>
      </div>
      <div class="checkboxes">
        <label v-for="brand in brands" :key="brand.id">
          <input type="checkbox" :value="brand.id" v-model="productFilter.brands">
          <span>{{brand.title}}</span>
        </label>
      </div>
    </div>
    <div class="dd__card" :class="{ 'open': productFilter.dropdown.price }">
      <div class="title" @click="productFilter.dropdown.price = !productFilter.dropdown.price">
        <span>{{$t('shop.price')}}</span>
      </div>
      <div class="price__range">
        <div class="inputs">
          <input type="number" id="min-price" v-model="sliderMinValue">
          <input type="number" id="max-price" v-model="sliderValue">
        </div>
        <div id="range-slider">
          <range-slider
            class="slider"
            min="0"
            max="2000"
            step="50"
            v-model="sliderValue"> <!--6422-->
          </range-slider>
        </div>
      </div>
    </div>
    <div class="dd__card">
      <div class="title">
        <span>{{$t('shop.filtreTitle')}}</span>
      </div>
      <div class="checkboxes">
        <label v-for="cat in categories" :key="cat.id">
          <input type="checkbox" :value="cat.id" v-model="productFilter.categories">
          <span>{{cat.name}}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="shop__cnt">
    <div class="filters__col">
      <div class="filter__btn" @click="productFilter.mobileVersion = !productFilter.mobileVersion">
        <span>{{$t('shop.filtreTitle')}}</span>
        <div class="icon"></div>
      </div>
      <select v-if="false">
        <option value="">Sortare</option>
      </select>
    </div>
  <div class="view_type">
    <div class="multiple_view" @click="gridMultipleView">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 256.000000 256.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M265 2295 l-25 -24 0 -431 0 -431 25 -24 24 -25 431 0 431 0 24 25 25 24 0 431 0 431 -25 24 -24 25 -431 0 -431 0 -24 -25z m775 -455 l0 -320 -320 0 -320 0 0 320 0 320 320 0 320 0 0 -320z"/>
          <path d="M1385 2295 l-25 -24 0 -431 0 -431 25 -24 24 -25 431 0 431 0 24 25 25 24 0 431 0 431 -25 24 -24 25 -431 0 -431 0 -24 -25z m775 -455 l0 -320 -320 0 -320 0 0 320 0 320 320 0 320 0 0 -320z"/>
          <path d="M265 1175 l-25 -24 0 -431 0 -431 25 -24 24 -25 431 0 431 0 24 25 25 24 0 431 0 431 -25 24 -24 25 -431 0 -431 0 -24 -25z m775 -455 l0 -320 -320 0 -320 0 0 320 0 320 320 0 320 0 0 -320z"/>
          <path d="M1385 1175 l-25 -24 0 -431 0 -431 25 -24 24 -25 431 0 431 0 24 25 25 24 0 431 0 431 -25 24 -24 25 -431 0 -431 0 -24 -25z m775 -455 l0 -320 -320 0 -320 0 0 320 0 320 320 0 320 0 0 -320z"/>
        </g>
      </svg>
    </div>
    <div class="single_view" @click="gridSingleView">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="45px" height="40px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M1370 4465 c-211 -45 -399 -193 -495 -389 -78 -160 -75 -104 -75 -1356 0 -1256 -3 -1195 77 -1360 39 -81 60 -109 137 -185 69 -69 111 -101 171 -132 162 -84 63 -78 1375 -78 1312 0 1213 -6 1375 78 60 31 102 63 171 132 77 76 98 104 137 185 80 165 77 104 77 1360 0 1252 3 1196 -75 1356 -73 149 -220 289 -370 351 -128 54 -116 53 -1322 52 -909 0 -1130 -3 -1183 -14z m2373 -330 c103 -42 195 -136 233 -238 18 -50 19 -88 19 -1177 0 -1089 -1 -1127 -19 -1177 -39 -105 -134 -200 -239 -239 -50 -18 -88 -19 -1177 -19 -1089 0 -1127 1 -1177 19 -102 38 -196 130 -238 233 -19 47 -20 78 -23 1157 -3 1223 -5 1178 59 1278 37 56 104 118 157 144 90 43 74 43 1242 41 1086 -2 1116 -3 1163 -22z"/>
      </g>
    </svg>
    </div>
  </div>
    <div class="shop__filters">
      <strong>{{$t('shop.filtreTitle')}}</strong>
      <div class="dd__card" :class="{ 'open': productFilter.dropdown.brands }">
        <div class="top" @click="productFilter.dropdown.brands = !productFilter.dropdown.brands">
          <span>{{$t('shop.brand')}}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4398 11.7825C16.5624 11.9019 16.5624 12.0989 16.4398 12.2184L7.98109 20.4599C7.86293 20.575 7.67455 20.575 7.55638 20.4599C7.43379 20.3404 7.43379 20.1434 7.55638 20.024L16.0151 11.7825C16.1333 11.6673 16.3217 11.6673 16.4398 11.7825Z" fill="#6B6B78"/>
            <path d="M7.55638 3.54031C7.43379 3.65975 7.43379 3.85677 7.55638 3.97621L16.0176 12.2201C16.1358 12.3352 16.3242 12.3352 16.4423 12.2201C16.5649 12.1007 16.5649 11.9036 16.4423 11.7842L7.98108 3.54031C7.86292 3.42518 7.67454 3.42518 7.55638 3.54031Z" fill="#6B6B78"/>
          </svg>
        </div>
        <div class="checkboxes">
          <label v-for="brand in brands" :key="brand.id">
            <input type="checkbox" :value="brand.id" v-model="productFilter.brands">
            <span>{{ brand.title }}</span>
          </label>       
        </div>
      </div>
      <div class="dd__card" :class="{ 'open': productFilter.dropdown.price }">
        <div class="top" @click="productFilter.dropdown.price = !productFilter.dropdown.price">
          <span>{{$t('shop.price')}}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4398 11.7825C16.5624 11.9019 16.5624 12.0989 16.4398 12.2184L7.98109 20.4599C7.86293 20.575 7.67455 20.575 7.55638 20.4599C7.43379 20.3404 7.43379 20.1434 7.55638 20.024L16.0151 11.7825C16.1333 11.6673 16.3217 11.6673 16.4398 11.7825Z" fill="#6B6B78"/>
            <path d="M7.55638 3.54031C7.43379 3.65975 7.43379 3.85677 7.55638 3.97621L16.0176 12.2201C16.1358 12.3352 16.3242 12.3352 16.4423 12.2201C16.5649 12.1007 16.5649 11.9036 16.4423 11.7842L7.98108 3.54031C7.86292 3.42518 7.67454 3.42518 7.55638 3.54031Z" fill="#6B6B78"/>
          </svg>
        </div>
        <div class="price__range">
          <div class="inputs">
            <input type="number" id="min-price" v-model="sliderMinValue">
            <input type="number" id="max-price" v-model="sliderValue">
          </div>
          <div id="range-slider">
            <range-slider
              class="slider"
              min="0"
              max="20000"
              step="50"
              v-model="sliderValue"> <!--6422-->
            </range-slider>
          </div>
        </div>
      </div>
      <div class="dd__card" :class="{ 'open': productFilter.dropdown.categories }">
        <div class="top" @click="productFilter.dropdown.categories = !productFilter.dropdown.categories">
          <span>{{$t('shop.categorie')}}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4398 11.7825C16.5624 11.9019 16.5624 12.0989 16.4398 12.2184L7.98109 20.4599C7.86293 20.575 7.67455 20.575 7.55638 20.4599C7.43379 20.3404 7.43379 20.1434 7.55638 20.024L16.0151 11.7825C16.1333 11.6673 16.3217 11.6673 16.4398 11.7825Z" fill="#6B6B78"/>
            <path d="M7.55638 3.54031C7.43379 3.65975 7.43379 3.85677 7.55638 3.97621L16.0176 12.2201C16.1358 12.3352 16.3242 12.3352 16.4423 12.2201C16.5649 12.1007 16.5649 11.9036 16.4423 11.7842L7.98108 3.54031C7.86292 3.42518 7.67454 3.42518 7.55638 3.54031Z" fill="#6B6B78"/>
          </svg>
        </div>
        <div class="checkboxes">
          <label v-for="cat in categories" :key="cat.id">
            <input type="checkbox" :value="cat.id" v-model="productFilter.categories">
            <span>{{ cat.name }}</span>
          </label>  
        </div>
      </div>

    </div>
    <div class="shop__catalog">
        <div class="filter_block">
          <div class="wrapp_v-select">
            <v-select class="nameSearch" :placeholder="$t('productSelect')" v-model="selectProductName" :options="nameVariants"></v-select>
        </div>
      </div>
      <div class="top">
        <div class="sort">
          <span>{{$t('shop.sorted')}}</span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.14453 10.9601C8.0649 11.0418 7.93356 11.0418 7.85393 10.9601L2.35961 5.32095C2.28286 5.24218 2.28286 5.11659 2.35961 5.03781C2.43924 4.95609 2.57058 4.95609 2.65021 5.03781L8.14453 10.677C8.22128 10.7558 8.22128 10.8813 8.14453 10.9601Z" fill="#000001"/>
            <path d="M13.6393 5.03781C13.5597 4.95608 13.4283 4.95608 13.3487 5.03781L7.85278 10.6786C7.77603 10.7574 7.77603 10.883 7.85278 10.9618C7.93241 11.0435 8.06375 11.0435 8.14338 10.9618L13.6393 5.32094C13.7161 5.24217 13.7161 5.11658 13.6393 5.03781Z" fill="#000001"/>
          </svg>
        </div>
        <div class="results__num">
          {{filterPrice.length}} {{$t('shop.result')}} 
        </div>
      </div>
      <div class="row">
       <CardShop 
        v-for="card in products"
        :id="card.id" 
        :key="card.id"
        :title="card.title"
        :image="card.image_thumbnail"
        :discount="Number(card[discountPrice])"
        :price="card[price]"
        :oldPrice="card[oldPrice]"
        :inStock="card[inStockProduct]"
        link="product"
        :params="{'user': userID}"
        :currency="currency"
        @anotherSpecialist="anotherId"
        :is_new="card.is_new"
        :product="card"
       />
      </div>
      <div class="load__more" @click="productFilter.pageCountCard += productFilter.pageCountCard" v-show="filterPrice.length != products.length">
        <span>{{$t('shop.loadPage')}}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.99593 5H9.99593V10H8.99593V6.493C7.6106 7.07272 6.46865 8.1141 5.76404 9.44027C5.05942 10.7664 4.83559 12.2956 5.13056 13.7681C5.42553 15.2406 6.22111 16.5656 7.38217 17.518C8.54323 18.4705 9.99819 18.9916 11.4999 18.993C13.0945 18.9946 14.6341 18.4099 15.8257 17.3502C17.0173 16.2906 17.7778 14.8299 17.9625 13.246C18.1473 11.6621 17.7433 10.0656 16.8275 8.76015C15.9118 7.45471 14.5481 6.53145 12.9959 6.166V5.142C14.7152 5.49183 16.2574 6.43335 17.3542 7.80274C18.4511 9.17213 19.0331 10.8827 18.9991 12.6369C18.965 14.3911 18.3171 16.0778 17.168 17.4036C16.0188 18.7294 14.4413 19.6104 12.7097 19.8933C10.9782 20.1762 9.20224 19.843 7.69092 18.9518C6.17961 18.0606 5.02856 16.6678 4.43805 15.0157C3.84754 13.3635 3.85493 11.5567 4.45895 9.9094C5.06296 8.26215 6.22537 6.87879 7.74393 6H4.99593V5Z" fill="#930684"/>
        </svg>
      </div>
    </div>
  </div>
  <FooterApp/>
  <!-- modal -->
  <div class="aq__modal__wrp" @click="closeModal" :class="{'active': wrongProductModal}">
    <div class="aq__modal">
      <div class="top">
        <div class="details">
          <!-- <strong>Adaugă înscriere</strong> -->
        </div>
        <div class="close__btn" @click="wrongProductModal = false">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="container">
        <!-- <img class="modal_logo" src="/img/logo2.svg"> -->
        <h3 class="title_end">{{wrongProductMsg}}</h3>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import LangRu from '@/lang/Shop/ru' 
import LangRo from '@/lang/Shop/ro' 
import LangEng from '@/lang/Shop/eng'
import LangEs from '@/lang/Shop/es'

import shopMixin from '@/mixins/shop'
import Breadcrumbs from "@/components/Breadcrumbs";
import SpecialistShopHeader from '@/components/SpecialistShopHeader'
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp";
import CardShop from "@/components/cards/CardShop"
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { AVAILABLE_COUNTRIES_MAP, DEFAULT_SHOP_ID_BY_COUNTRY_MAP } from '@/utils/helpers'
const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins:[LangRu, LangRo, LangEng, LangEs, shopMixin],
  components: {
    HeaderWrp,
    FooterApp,
    RangeSlider,
    CardShop,
    SpecialistShopHeader,
    Breadcrumbs
  },
  beforeDestroy(){
    this.changeShopOptions(this.productFilter);
  },
  data(){
    return {
      loaded: false,
      selectProductName: '',
      productFilter: {
        pageCountCard: 6,
        categories: [],
        brands: [],
           
        dropdown: {
          brands: false,
          price: false,
          categories: false   
        },
        mobileVersion: false
      },
      sliderValue: 15000,
      sliderMinValue: 0,
      specialistProducts: [],
      localSpecialist: "Moldova, Republic of",
      user: null,
      wrongProductModal: false,
      wrongProductMsg: ""
    }
  },
  async beforeMount(){
    // this.$store.commit("checkout/reset");
    // await this.getUser();
    await this.setLocalSpecialist(this.userID)
    
    // bridge between old logic and new
    this.user = _.cloneDeep(this.$store.state.specialists.localSpecialist)
    
    const visitorCountryCode = await this.getVisitorCountryCode()
    if (!visitorCountryCode) { window.location.href = `/${this.langStorage}/` }
    if (visitorCountryCode !== 'RO' && visitorCountryCode !== 'MD') { 
      window.location.href = `/${this.langStorage}/`
      return
    }

    try {
      const country = AVAILABLE_COUNTRIES_MAP[visitorCountryCode]
      if (this.user?.city !== country) {
        const shopIdToRedirect = DEFAULT_SHOP_ID_BY_COUNTRY_MAP[country]
        window.location.href = `/${this.langStorage}/shop/${shopIdToRedirect}/`
      }
    } catch (e) {
      console.error(e)
      window.location.href = `/${this.langStorage}/`
    }
    if(this.user?.shop_is_active) {
      this.localSpecialist = this.user.city;
      await this.setSpecialistProducts({'code': this.countryCode, 'filter': this.isUserLica});
      this.specialistProducts = this.getSpecialistProducts.results;
      this.productFilter = _.cloneDeep(this.shopOptions.productFilter);
      this.setCategories();
      this.setBrands();
      this.loaded = true
    }
    else this.$router.push({name: "Home"});
  },
  watch: {
    async langStorage() {
      await this.setSpecialistProducts({'code': this.countryCode, 'filter': this.isUserLica});
      this.specialistProducts = this.getSpecialistProducts.results;
      this.setCategories();
      this.setBrands();
    }
  },
  computed: {
    ...mapGetters({
    categories: 'shopCards/getCategories',
    brands: 'shopCards/getBrands',
    shopOptions: 'shopCards/getShopOptions',
    langStorage : 'languages/getLang',
    getSpecialistProducts: "shopCards/getSpecialistItems",
    storeUser: "user/user"
   }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    nameVariants(){
      let nameArray = [];
      this.filterPrice.forEach( el =>{
        if(el.title != null && el.title != 'null' && !nameArray.includes(el.title)){
          nameArray.push(el.title)
        }
      });
      return nameArray
    },
    filterByName(){
      return this.filterPrice.filter(el => !this.selectProductName || el.title == this.selectProductName)
    },
    products() {
      let w = screen.width
      if(w < 431){
        return this.filterPrice.filter(el => !this.selectProductName || el.title == this.selectProductName)
      }
      else{
        return this.filterPrice.filter(el => !this.selectProductName || el.title == this.selectProductName).slice(0, this.productFilter.pageCountCard);
      }
    },
    filterCategory(){
      if(this.productFilter.categories.length) {
        return this.specialistProducts.filter(prod => {
          return this.productFilter.categories.includes(prod.category);
        });
      }
      else {
        return this.specialistProducts;
      } 
    },
    filterBrands(){
       if(this.productFilter.brands.length) {
        return this.filterCategory.filter(prod => {
          for(let i = 0; i < prod.brand.length; i++){
            if(this.productFilter.brands.includes(prod.brand[i].id)){
              return true;
            }
          }
          return false;                 
        });
      }
      else return this.filterCategory;
    },
    filterPrice(){
      return this.filterBrands.filter(prod => {
        let price;
        if(this.localSpecialist == "Romania"){
          price = Number(prod.client_price_ro);        
        }
        else if(this.localSpecialist == "Russian Federation"){
          price = Number(prod.client_price_ru);
        }
        else{
          price = Number(prod.client_price)
        }
        return ((prod.active) && (price >= this.sliderMinValue) && (price <= this.sliderValue));
      });
    },
    price(){
      if(this.localSpecialist == "Romania"){
        return 'client_price_ro'
      }
      else if(this.localSpecialist == "Russian Federation") {
        return 'client_price_ru';
      }
      else{
        return 'client_price';
      }
    },
    oldPrice(){
      if(this.localSpecialist == "Romania"){
        return 'client_price_old_ro'
      }
      else if(this.localSpecialist == "Russian Federation"){ 
        return 'client_price_old_ru';
      }
      else{
        return 'client_price_old';
      }
    },
    discountPrice(){
      if(this.localSpecialist == "Romania"){
        return 'client_discount_ro'
      }
      else if(this.localSpecialist == "Russian Federation"){
        return 'client_discount_ru';
      }
      else{
        return 'client_discount'
      }
    },
    inStockProduct(){
      if(this.localSpecialist == "Romania"){
        return 'client_in_stock_for_ro'
      }
      else if(this.localSpecialist == "Russian Federation"){
        return 'client_in_stock_for_ru'
      }
      else{
        return 'client_in_stock_for_md'
      }
    },
    countryCode(){
      if(this.localSpecialist == "Romania"){
        return "ro"
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "ru"
      }
      else{
        return "md"
      }
    },
    currency(){
      if(this.localSpecialist == "Romania"){
        return 'RON'
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "RUB"
      }
      else{
        return "MDL"
      }
    },
    userID(){
      return this.$route.params.id;
    },
    isUserLica() {
      return this.userID == "104";
    },
  },
  methods: {
        gridMultipleView(){
      const row = document.querySelector('.row');
      const card = row.children;
      card.forEach(el =>{
        el.classList.add('grid_view')
      })
    },
    gridSingleView(){
      const row = document.querySelector('.row');
      const card = row.childNodes;
      card.forEach(el =>{
        el.classList.remove('grid_view')
      })
    },
    ...mapActions({
      setCategories: 'shopCards/setCategories',
      setBrands: 'shopCards/setBrands',
      changeShopOptions: 'shopCards/changeShopOptions',
      setSpecialistProducts: 'shopCards/setSpecialistProducts',
      setLocalSpecialist: 'specialists/setLocalSpecialist'
    }),
    async getProducts(){
      try {
        let arr = [];
        const request = await fetch(`${domain}/${this.langStorage}/shop/product/?active=true&is_active_for_client_${this.countryCode}=true&page_size=10000`);
        const data = await request.json();
        arr.push(data.results);

        async function getPage(url){
          let req = await fetch(url);
          let data = await req.json();
          arr.push(data.results);
          if(data.next){
            await getPage(data.next);
          }
          else return;
        }
        if(data.next){
          await getPage(data.next);
        }
        return arr.flat().filter(el => { return el.client_price != null || el.client_price_ro != null || el.client_price_ru != null });
      }
      catch(er){
        console.error(er);
      }
    },
    async getUser(){
      try {
        let request = await fetch(`${domain}/accounts/users/${this.userID}/`);
        if(request.ok){
          let e = await request.json();
          if(e.profile){
            this.user = {
              ...e.profile,
              id: e.id,
              photo: e.profile.image == null ? '/img/avatar-dashboard.png' : e.profile.image,
              name: e.profile.name,
              position: e.profile.specialization,
              price: e.profile.consultation_price,
              money_type: e.profile.currency.toUpperCase(),
              about: e.profile.description,
              status: e.profile.landing_page_is_active,
              certificates: e.profile.certificates_and_diplomas,
              works: e.profile.user_page_slide,
              address: e.profile.address,
            };
  
          }
          else throw "user no profile";
        }
        else {
          console.error(request);
          this.$router.push({name: "Home"});
        }
      }
      catch(ex) {
        console.error(ex);
      }
    },
    anotherId(){
      const message = {
        ro: "Acțiunea este limitată! Ați adăugat deja un produs de la un alt specialist sau acest produs este destinat clienților",
        ru: "Действие ограничено! Вы уже добавили в корзину товар от другого специалиста, или данный товар предназначен для клиентов",
        en: "You have already added a product from another specialist to the cart, or this product is intended for customers",
        es: "Ya has añadido un producto de otro especialista al carrito, o este producto está destinado a clientes"
      }

      if(window.innerWidth > 430){
        this.wrongProductMsg = message[this.langStorage]
        this.wrongProductModal = true;
        setTimeout(() => this.wrongProductModal = false, 4000);
      }
      else {
        alert(message[this.langStorage]);
      }
    },
    closeModal(e){
     if(e.target.classList == 'aq__modal__wrp active'){
      this.wrongProductModal = false;
     }
    }
  },
}
</script>
<style lang="scss">
.view_type{
  display: none;
}
.vs--searchable .vs__dropdown-toggle{
  border-radius: 48px;
  height: 48px;
  }
  .vs__selected-options{
     align-items: center;
  }
@media(min-width: 430px) and (max-width: 1024px){
   .filter_block{
    width: 100% !important;
  }
  .vs__selected-options{
    flex-direction: row;
  }
}
@media(max-width: 430px){
  .filter_block{
    width: 100% !important;
  }
  .vs__selected-options{
    flex-direction: row;
    align-items: center;
  }
  .scroll-up .shop__filters__mobile.active{
    transform: translateY(104px) !important;
  }
  .scroll-down .shop__filters__mobile.active{
    transform: translateY(0px) !important
  }
 .load__more{
    display: none;
  }
  .shop__catalog .row .grid_view .details .btm{
    flex-direction: column;
  }
  .shop__catalog .row .grid_view .details .btm .add__cart{
    width: 150px;
  }
  .shop__catalog .row .grid_view .details .btm .prices .current{
    font-size: 20px;
  }
  .shop__catalog .row .grid_view .details .title{
    font-size: 11px;
  }
  .shop__catalog .row .grid_view .details .title{
    margin-bottom: 0;
  }
  .grid_view{
    width: 50% !important;
  }
  .night .view_type .multiple_view svg g{ 
    fill: #ffffff
  }
  .night .view_type .single_view svg g{
    fill: #ffffff
  }
  .multiple_view{
    margin-top: 0.26em;
  }
  .view_type{
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
  }
}
    .filter_block {
      width: 60%;
      height: 80px;
      margin-left: auto;
      margin-right: 0;
      flex-direction: row;
      .wrapp_v-select {
        .nameSearch {
          .vs__search {
            background: url('/img/search-icon.svg') no-repeat;
            background-position: right 0px ;
          }
          .vs__actions {
            .vs__open-indicator {
             display: none;
            }
          }
        }
      }
    }
  .range-slider {
    width: 100%;
    padding: 0;
  }

  .range-slider-rail {
    height: 40px;
    border-radius: 40px;
    background-color: #f5f4fb;
  }
  .range-slider-fill {
    border-radius: 40px;
    height: 40px;
    background: #6b6b78;
  }
  .range-slider-knob {
    box-shadow: none;
    background-color: transparent;
    border: none;
    background-image: url("/img/range-max.svg");
    transform: translate(-145%, -55%);
  }

 .shop__cnt {
   max-width: 1768px !important;
  }

  .shop__filters .toggle input:checked + .toggle-control::after {
    background: #EB236B;
  }
</style>

